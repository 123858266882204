// AUTHENTICATION

// USER REGISTER AUTH
export const USER_REGISTER = "USER_REGISTER";
export const USER_REGISTER_SUCCESS = "USER_REGISTER_SUCCESS";
export const USER_REGISTER_ERROR = "USER_REGISTER_ERROR";
// USER LOGIN AUTH
export const USER_LOGIN = "USER_LOGIN";
export const USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS";
export const USER_LOGIN_ERROR = "USER_LOGIN_ERROR";
// USER LOGOUT AUTH
export const USER_LOGOUT = "USER_LOGOUT";
export const USER_LOGOUT_SUCCESS = "USER_LOGOUT_SUCCESS";
export const USER_LOGOUT_ERROR = "USER_LOGOUT_ERROR";
// FORGET PASSWORD AUTH
export const FORGET_PASSWORD = "FORGET_PASSWORD";
export const FORGET_PASSWORD_SUCCESS = "FORGET_PASSWORD_SUCCESS";
export const FORGET_PASSWORD_ERROR = "FORGET_PASSWORD_ERROR";
// RESET PASSWORD AUTH
export const RESET_PASSWORD = "RESET_PASSWORD";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_ERROR = "RESET_PASSWORD_ERROR";


// ASSETS

// GET ALL ASSETS
export const GET_ALL_USER_ASSETS = "GET_ALL_USER_ASSETS";
export const GET_ALL_USER_ASSETS_SUCCESS = "GET_ALL_USER_ASSETS_SUCCESS";
export const GET_ALL_USER_ASSETS_ERROR = "GET_ALL_USER_ASSETS_ERROR";

// UPLOAD ASSETS
export const UPLOAD_ASSET = "UPLOAD_ASSET";
export const UPLOAD_ASSET_SUCCESS = "UPLOAD_ASSET_SUCCESS";
export const UPLOAD_ASSET_ERROR = "UPLOAD_ASSET_ERROR";
